import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import config from '../config';

const languages = config.dataNames[0].aiLanguages;

const AiLangs = ({ onLanguageChange }) => {
  const location = useLocation();
  const [selectedLangs, setSelectedLangs] = useState(() => {
    const aiParam = (new URLSearchParams(location.search)).get('aiLangs');
    if (aiParam) {
      return aiParam.split(',');
    }    
    const storedLangs = localStorage.getItem('selectedLangs');
    return storedLangs ? storedLangs.split(',') : [];
  });

  const [isExpanded, setIsExpanded] = useState(() => {
    const storedExpanded = localStorage.getItem('aiLangsExpanded');
    return storedExpanded === null ? true : JSON.parse(storedExpanded);
  });

  useEffect(() => {
    localStorage.setItem('selectedLangs', selectedLangs.join(','));
    onLanguageChange(selectedLangs.join(','));
  }, [selectedLangs, onLanguageChange]);

  useEffect(() => {
    localStorage.setItem('aiLangsExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    setSelectedLangs(prev => 
      event.target.checked ? [...prev, lang] : prev.filter(l => l !== lang)
    );
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Box display="flex" alignItems="center">
        <Typography className="section-header" gutterBottom>Select Languages</Typography>
        <IconButton onClick={toggleExpand} size="small">
          {isExpanded ? <ExpandLessIcon style={{color: '#8ecae6', border: 'dashed #8ecae6'}} /> : <ExpandMoreIcon style={{color: '#a2d2ff', border: 'dashed #a2d2ff'}} />}
        </IconButton>
      </Box>
      {isExpanded && (
        <Grid container spacing={1}>
          {languages.map((lang, index) => (
            <Grid item xs={6} sm={4} md={2} lg={1.2} key={lang.code}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLangs.includes(lang.code)}
                    onChange={handleLanguageChange}
                    value={lang.code}
                  />
                }
                label={lang.word}
              />
              <br /><sup>{lang.name}</sup>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default AiLangs;