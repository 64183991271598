import React, { useState, useEffect, useRef, useCallback } from 'react';
import { fetchTopSubjects, getShareUrl } from '../services/apiService'; 
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import config from '../config';
import './PrintableSubjects.css'; 
import AdModal from './AdModal';

const PrintableSubjects = () => {
  const location = useLocation();

  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedLangs, setSelectedLangs] = useState(() => {
    const aiParam = (new URLSearchParams(location.search)).get('aiLangs');
    if (aiParam) {
      return aiParam.split(',');
    }      
    const storedLangs = localStorage.getItem('selectedLangs');
    return storedLangs ? storedLangs.split(',') : [];
  });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [scrollCount, setScrollCount] = useState(0);
  const [showAdModal, setShowAdModal] = useState(false);
  const loader = useRef(null);
  const adLoaded = useRef(false);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  
  const dataName = config.dataNames[0];
  const subjectName = dataName.subject;

  useEffect(() => {
    const loadSubjects = async () => {
      try {
        const data = await fetchTopSubjects({page, rowsPerPage, aiLangs: selectedLangs});
        setSubjects((prevSubjects) => [...prevSubjects, ...data]);
      } catch (err) {
        console.error('Failed to load subjects:', err);
      }
    };

    loadSubjects();
  }, [page]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prevPage) => prevPage + 1);
      setScrollCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount % 3 === 0) {
          setShowAdModal(true);
        }
        return newCount;
      });
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [handleObserver]);

  const handlePrint = () => {
    window.print();
  };

  const handleCloseAdModal = () => {
    setShowAdModal(false);
    // Clear the ad when closing the modal
    const adElement = document.querySelector('.adsbygoogle');
    if (adElement) {
      adElement.innerHTML = '';
      adElement.classList.remove('adsbygoogle');
    }
    adLoaded.current = false;
  };

  useEffect(() => {
    if (showAdModal && !adLoaded.current) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        adLoaded.current = true;
      } catch (e) {
        console.error('Ad error:', e);
      }
    }
  }, [showAdModal]);

  const joinedLangs = aiField => aiField && Object.entries(aiField).map(([language, phrases]) => {
    return (
      <>
        <p className="subject-phrase"><i>{phrases[0]}</i></p>
      </>
    );
  });

  return (
    <div className="printable-subjects-container">
      <button onClick={handlePrint} className="print-button">Print</button>
      <div className="subjects-list">
        {subjects.map((subject, index) => (
          <div key={subject.id} className="subject-item">
            <a href={getShareUrl(subject[subjectName], lang, selectedLangs)} target="_blank" rel="noopener noreferrer" className="subject-link">
              {index + 1}. {subject[subjectName]}
            </a><br />
            {joinedLangs(subject[dataName.aiFields.similarPhrases])}
          </div>
        ))}
        <div ref={loader} />
      </div>
      <AdModal open={showAdModal} handleClose={handleCloseAdModal} />
    </div>
  );
};

export default PrintableSubjects;