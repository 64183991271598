// PhraseSudokuGenerator.js

class PhraseSudokuGenerator {
  constructor(phrases) {
    this.originalPhrases = phrases.map(phrase => this.capitalizeFirstLetter(phrase));
    this.phrases = this.preprocessPhrases(this.originalPhrases);
    this.size = this.determineGridSize();
    this.grid = Array(this.size).fill().map(() => Array(this.size).fill('#'));
    this.solution = Array(this.size).fill().map(() => Array(this.size).fill('#'));
  }

  capitalizeFirstLetter(phrase) {
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  }

  preprocessPhrases(phrases) {
    return phrases
      .filter(phrase => phrase.length <= 16)
      .map(phrase => phrase.replace(/ /g, '_'))
      .sort((a, b) => b.length - a.length)
      .slice(0, 9);  // Ensure we have at most 9 phrases
  }

  determineGridSize() {
    const maxLength = Math.max(...this.phrases.map(phrase => phrase.length));
    return Math.max(9, maxLength);  // Ensure the grid is at least 9x9
  }

  generate() {
    this.fillGrid();
    this.createPuzzle();
    return {
      grid: this.grid,
      solution: this.solution,
      phrases: this.originalPhrases.filter(phrase => this.phrases.includes(phrase.replace(/ /g, '_'))),
      size: this.size
    };
  }

  fillGrid() {
    const shuffledPhrases = this.shuffleArray([...this.phrases]);
    for (let phrase of shuffledPhrases) {
      if (!this.placePhrase(phrase)) {
        console.error(`Failed to place phrase: ${phrase}`);
      }
    }
  }

  placePhrase(phrase) {
    const directions = this.shuffleArray(['horizontal', 'vertical']);
    for (let direction of directions) {
      if (direction === 'horizontal') {
        for (let row = 0; row < this.size; row++) {
          if (this.placeHorizontally(phrase, row)) return true;
        }
      } else {
        for (let col = 0; col < this.size; col++) {
          if (this.placeVertically(phrase, col)) return true;
        }
      }
    }
    return false;
  }

  placeHorizontally(phrase, row) {
    const availableSpots = this.size - phrase.length + 1;
    const shuffledPositions = this.shuffleArray([...Array(availableSpots).keys()]);
    for (let startCol of shuffledPositions) {
      if (this.canPlacePhrase(phrase, row, startCol, 'horizontal')) {
        for (let i = 0; i < phrase.length; i++) {
          this.solution[row][startCol + i] = phrase[i];
        }
        return true;
      }
    }
    return false;
  }

  placeVertically(phrase, col) {
    const availableSpots = this.size - phrase.length + 1;
    const shuffledPositions = this.shuffleArray([...Array(availableSpots).keys()]);
    for (let startRow of shuffledPositions) {
      if (this.canPlacePhrase(phrase, startRow, col, 'vertical')) {
        for (let i = 0; i < phrase.length; i++) {
          this.solution[startRow + i][col] = phrase[i];
        }
        return true;
      }
    }
    return false;
  }

  canPlacePhrase(phrase, startRow, startCol, direction) {
    if (direction === 'horizontal') {
      if (startCol + phrase.length > this.size) return false;
      for (let i = 0; i < phrase.length; i++) {
        if (this.solution[startRow][startCol + i] !== '#' && this.solution[startRow][startCol + i] !== phrase[i]) return false;
      }
    } else {
      if (startRow + phrase.length > this.size) return false;
      for (let i = 0; i < phrase.length; i++) {
        if (this.solution[startRow + i][startCol] !== '#' && this.solution[startRow + i][startCol] !== phrase[i]) return false;
      }
    }
    return true;
  }

  createPuzzle() {
    for (let row = 0; row < this.size; row++) {
      for (let col = 0; col < this.size; col++) {
        if (this.solution[row][col] !== '#' && Math.random() < 0.5) {
          this.grid[row][col] = this.solution[row][col];
        } else {
          this.grid[row][col] = this.solution[row][col] === '#' ? '#' : '';
        }
      }
    }
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}

export default PhraseSudokuGenerator;