// PhraseSudokuModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Tabs, Tab, IconButton, Grid } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getShareUrl } from '../services/apiService'; 
import config from '../config';

const PhraseSudokuModal = ({ open, onClose, sudokuData, selectedSubjects }) => {
  const [tabValue, setTabValue] = useState(0);
  const [logoData, setLogoData] = useState('');
  const [logoDataUrl, setLogoDataUrl] = useState('');

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const dataName = config.dataNames[0];
  const subjectName = dataName.subject;

  const [selectedLangs, setSelectedLangs] = useState(() => {
    const storedLangs = localStorage.getItem('selectedLangs');
    return storedLangs ? storedLangs.split(',') : [];
  });

  useEffect(() => {
    fetch('./logo192.png')
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => setLogoDataUrl(reader.result);
        reader.readAsDataURL(blob);
      });
  }, []);

  useEffect(() => {
    fetch('./profoundwords-logo192.png')
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => setLogoData(reader.result);
        reader.readAsDataURL(blob);
      });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePrint = () => {
    const printWindow = window.open('', '', 'width=900,height=650');
    printWindow.document.write(`
      <html>
        <head>
          <title>Phrases Puzzle</title>
          <style>
            body { font-family: Arial, sans-serif }
            .page { position: relative; height: 100vh; }
            .page:first-of-type { break-after: auto; }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px;
            }
            .left-logo {
              flex: 0 0 auto;
            }
            .profound-logo {
              max-height: 40px; /* Adjust as needed */
              width: auto;
            }
            .center-logo {
              flex: 1 1 auto;
              text-align: center;
            }
            .logo { max-width: 64px; max-height: 64px; }
            .sudoku-grid { 
              display: inline-block;
              border-collapse: collapse;
              margin: 0 auto;
            }
            .sudoku-cell {
              width: 30px;
              height: 30px;
              border: 1px solid black;
              text-align: center;
              font-weight: bold;
            }
            .solution { color: red; }
            .notes, .phrases { margin-top: 5px; }
            .url { position: absolute; bottom: 20px; right: 20px; }
            td[style*="background-color: grey"] { background-color: grey !important; print-color-adjust: exact; -webkit-print-color-adjust: exact; }
          </style>
        </head>
        <body>
          <div class="print-content">
            ${renderPrintPage(sudokuData.grid, false)}
            ${renderPrintPage(sudokuData.grid, true)}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const joinedLangsWithoutTag = aiField => aiField && Object.entries(aiField).map(([language, phrases], index) => (
      phrases[0]
  ));
  const renderPrintPage = (grid, isSolution) => `
  <div class="page">
    <div class="header">
      <div class="left-logo">
        <a href="https://profoundwords.us/">
          <img src="${logoData || './profoundwords-logo192.png'}" alt="Profound Words" class="profound-logo" />
        </a>
      </div>
      <div class="center-logo">
        <a href="${window.location.href}">
          <img src="${logoDataUrl || './logo192.png'}" alt="Logo" class="logo" />
        </a>
      </div>
    </div>
    <p><b>${isSolution ? 'Solution' : 'Puzzle'}</b></p>    
    <table class="sudoku-grid">
      ${grid.map((row, rowIndex) => `
        <tr>
          ${row.map((cell, cellIndex) => `
            <td 
              class="sudoku-cell ${isSolution && sudokuData.solution[rowIndex][cellIndex] !== cell ? 'solution' : ''}"
              style="${cell === '#' ? 'background-color: grey;' : ''}"
            >
              ${cell === '#' ? '' : (isSolution ? sudokuData.solution[rowIndex][cellIndex] : cell)}
            </td>
          `).join('')}
        </tr>
      `).join('')}
    </table>
    <div class="notes">
      <p style="font-size:12px;">Notes:</p>
      <ul style="font-size:10px;">
        <li>If it has underscore ("_") which means a space.</li>
        <li>Cells filled with grey color that means unused cells, you can ignore them.</li>
      </ul>
    </div>
    <div class="phrases">
      <p>Possible phrases used in this puzzle:</p>
      <ul>
        ${sudokuData.phrases?.map(phrase => {
          const subject = selectedSubjects?.find(p => p[subjectName][0].toLowerCase() === phrase?.toLowerCase());
          if (!subject) {
            return null;
          }
          let similarLangs = subject && joinedLangsWithoutTag(subject[`${dataName.aiFields.similarPhrases}`]);
          similarLangs = similarLangs?.join('  |  ') || '';
          return (
            `<li>
              <a href=${getShareUrl(subject[subjectName], lang, selectedLangs)} target="_blank" rel="noopener noreferrer" className="subject-link">
                ${phrase}
              </a>
              <ul>
                ${similarLangs}
              </ul>
            </li>`
          );
        }).join('')}
      </ul>
    </div>
    <div class="url"><a href="${window.location.href}">${window.location.href}</a></div>
  </div>
`;

  const renderSudokuGrid = (grid, solution = null) => (
    <div id="sudoku-grid">
      {grid.map((row, rowIndex) => (
        <div key={rowIndex} style={{display: 'flex'}}>
          {row.map((cell, cellIndex) => (
            <div key={cellIndex} style={{
              border: '1px solid black',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              backgroundColor: cell === '#' ? 'grey' : 'white',
              color: solution && solution[rowIndex][cellIndex] !== cell ? 'red' : 'black'
            }}>
              {cell === '#' ? '' : (solution ? solution[rowIndex][cellIndex] : cell)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const joinedLangs = aiField => aiField && Object.entries(aiField).map(([language, phrases], index) => (
    <React.Fragment key={language}>
      <li><i>{phrases[0]}</i></li>
    </React.Fragment>
  ));  

  const renderPhrasesList = () => (
    <>
      <Typography variant="h6" component="h5" style={{marginTop: '0px'}}>
        Possible Phrases:
      </Typography>
      <ul>
        {sudokuData.phrases?.map((phrase, index) => {
          const subject = selectedSubjects?.find(p => p[subjectName][0].toLowerCase() === phrase?.toLowerCase());
          if (!subject) {
            return null;
          }
          return (
            <li key={index}>
              <a href={getShareUrl(subject[subjectName], lang, selectedLangs)} target="_blank" rel="noopener noreferrer" className="subject-link">
                {phrase}
              </a>
              <ul>
                {joinedLangs(subject[`${dataName.aiFields.similarPhrases}`])}
              </ul>
            </li>
          );
        })}
      </ul>
      <div style={{fontSize: 'small', marginTop: '20px'}}>
        <p>Notes:</p>
        <ul>
          <li>If it has underscore ("_") which means a space.</li>
          <li>Cells filled with grey color that means unused cells, you can ignore them.</li>
        </ul>
      </div>
      <Button 
        variant="text" 
        onClick={handlePrint} 
        style={{ marginTop: '10px' }} 
        startIcon={<PrintIcon />}
      >
        Print
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="phrase-sudoku-modal"
      aria-describedby="phrase-sudoku-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
      }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="phrase-sudoku-modal" variant="h6" component="h2">
          Phrases Puzzle
        </Typography>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Puzzle" />
          <Tab label="Solution" />
        </Tabs>
        {tabValue === 0 && sudokuData && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {renderSudokuGrid(sudokuData.grid)}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderPhrasesList()}
            </Grid>
          </Grid>
        )}
        {tabValue === 1 && sudokuData && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {renderSudokuGrid(sudokuData.grid, sudokuData.solution)}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderPhrasesList()}
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default PhraseSudokuModal;